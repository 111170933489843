@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 $sidebar-width;
    min-height: 100vh;
  }

  .navbar-static-side {
    z-index: 2001;
    position: absolute;
    width: $sidebar-width;
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

@media (max-width: 768px) {

  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0;
    min-height: 100vh;
  }

  .body-small .navbar-static-side {
    display: none;
    z-index: 2001;
    position: absolute;
    width: 70px;
  }

  .body-small.mini-navbar .navbar-static-side {
    display: block;
  }

  .lock-word {
    display: none;
  }

  .navbar-form-custom {
    display: none;
  }

  .navbar-header {
    display: inline;
    float: left;
  }

  .sidebar-panel {
    z-index: 2;
    position: relative;
    width: auto;
    min-height: 100% !important;
  }

  .sidebar-content .wrapper {
    padding-right: 0;
    z-index: 1;
  }

  .fixed-sidebar.body-small .navbar-static-side {
    display: none;
    z-index: 2001;
    position: fixed;
    width: $sidebar-width;
  }

  .fixed-sidebar.body-small.mini-navbar .navbar-static-side {
    display: block;
  }

  .ibox-tools {
    float: none;
    text-align: right;
    display: block;
  }

  .navbar-static-side { display: none; }

  body:not(.mini-navbar) {
    -webkit-transition: background-color 500ms linear;
    -moz-transition: background-color 500ms linear;
    -o-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
    background-color: #f3f3f4
  }

  //SIDEBAR TESTING
  body.mini-navbar #page-wrapper {
  margin: 0 0 0 55px;
    // margin: 0 0 0 0;//testing
  }

  nav.navbar.navbar-static-top{
    margin-left: 0px !important;
    // position: absolute;
  }

  nav.navbar-default.navbar-static-side {
    background-color: #2F4050;
    position: fixed;
    height: 100%;
    -webkit-transition: background-color 500ms linear;
    -moz-transition: background-color 500ms linear;
    -o-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
  }

  .slide-navbar {
    margin-left: 70px !important;
    
  }
  //SIDEBAR TESTING

}


@media (max-width: 350px) {

  .timeline-item .date {
    text-align: left;
    width: 110px;
    position: relative;
    padding-top: 30px;
  }

  .timeline-item .date i {
    position: absolute;
    top: 0;
    left: 15px;
    padding: 5px;
    width: 30px;
    text-align: center;
    border: 1px solid $border-color;
    background: #f8f8f8;
  }

  .timeline-item .content {
    border-left: none;
    border-top: 1px solid $border-color;
    padding-top: 10px;
    min-height: 100px;
  }

  .nav.navbar-top-links li.dropdown {
    display: none;
  }

  .ibox-tools {
    float: none;
    text-align: left;
    display: inline-block;
  }

}
