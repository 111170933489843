/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// Variables, Mixins
@import "imports/variables";
@import "imports/mixins";
@import "imports/media";

// Landing page styles
@import "imports/landing";
@import "imports/metismenu";
@import "imports/_spinners";

.mat-mdc-tab-labels {
  display: flex !important;
  flex: 1 0 auto !important;

  @media screen and (max-width: 600px) {
    flex-direction: column !important;
    align-items: center !important;
  }
}
